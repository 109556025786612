.react-autosuggest__container {
    position: relative;
  }
  
  .react-autosuggest__input {
    width: 100%;
    height: 25px;
    line-height: normal;
    padding: 0 20px;
    font-weight: 300;
    font-size: 14px;
    border: 1px solid #aaa;
    border-radius: 4px;
    z-index: 1000;
  }
  
  .react-autosuggest__input--focused {
    outline: none;
    border: 1px solid #7c7e7d;
  }
  
  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    z-index: 1000;
  }
  
  .react-autosuggest__suggestions-container {
    display: none;
  }
  
  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 24px;
    width: 100%;
    max-height: 30vh;
    border: 1px solid #7c7e7d;
    background-color: #fff;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 1000;
    overflow-y: auto;
    color: black;
  }

  @media (max-width: 576px) {
    .react-autosuggest__suggestions-container--open {
      width: 100%;
      z-index: 1000;
    }

    .react-autosuggest__input {
      width: 100%;
      z-index: 1000;
    }
  }
  
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    z-index: 1000;
  }
  
  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }
  
  .react-autosuggest__suggestion--highlighted {
    background-color: rgb(240, 240, 240);
    z-index: 1000;
  }
  
  .react-autosuggest__suggestion-match {
    color: red;
    font-weight: bold;
    z-index: 1000;
  }

  .react-autosuggest__section-title {
    padding: 10px 0 0 10px;
    font-size: 12px;
    color: #777;
    font-weight: 500;
  }